<template>
  <div style="padding: 20px">
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>网站信息管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!--主体内容-->
    <el-card :style="{ height: screenHeight + 'px', overflow: 'auto' }">
      <a-row :gutter="20">
        <a-col
          :xs="{ span: 24 }"
          :sm="{ span: 24 }"
          :md="{ span: 12 }"
          :lg="{ span: 12 }"
          :xl="{ span: 8 }"
          v-for="item in websiteList"
          :key="item.id"
        >
          <a-card
            hoverable
            :style="{ width: '100%', minWidth: '320px', marginBottom: '20px' }"
            :key="item.id"
          >
            <div>
              <div class="websit-tag" v-if="item.isMain">默认</div>
              <!--卡片头部-->
              <div class="websit-card-head">
                <a-row type="flex">
                  <a-col flex="50px" class="websit-card-img">
                    <a-avatar
                      v-if="
                        item.menuLogoAddressAll === null ||
                        item.menuLogoAddressAll === ''
                      "
                      shape="square"
                      :size="50"
                      icon="home"
                    />
                    <img
                      v-else
                      class="logo-img"
                      :src="item.menuLogoAddressAll"
                      alt=""
                    />
                  </a-col>
                  <a-col class="websit-company-name" flex="auto">{{
                    item.organizationName || "未知"
                  }}</a-col>
                </a-row>
              </div>

              <!--卡片内容-->
              <div class="websit-card-body">
                <div class="websit-card-box">
                  <div class="websit-card-body-body">
                    <span class="websit-card-tile">网站域名：</span
                    >{{ item.key || "暂未设置" }}
                  </div>
                </div>
                <div class="websit-card-box">
                  <div class="websit-card-body-body">
                    <span class="websit-card-tile">网站标题：</span
                    >{{ item.webTitle || "暂未设置" }}
                  </div>
                </div>
                <div class="websit-card-box">
                  <div class="websit-card-body-body">
                    <span class="websit-card-tile">菜单标题：</span
                    >{{ item.menuTitle || "暂未设置" }}
                  </div>
                </div>
                <div class="websit-card-box">
                  <div class="websit-card-body-body">
                    <span class="websit-card-tile">版权声明：</span
                    >{{ item.webCopyright || "暂未设置" }}
                  </div>
                </div>
              </div>
            </div>
            <!--卡片底部-->
            <template class="ant-card-actions" slot="actions">
              <a-icon key="edit" type="edit" @click="showWebsiteEdit(item)" />
              <a-icon
                key="delete"
                type="delete"
                @click="showWebsiteDelete(item.id)"
              />
            </template>
          </a-card>
        </a-col>
      </a-row>
    </el-card>

    <!-- 编辑、新增网站设置 -->
    <a-drawer
      title="编辑网站信息"
      :width="700"
      placement="right"
      :visible="editVisible"
      :closable="false"
      @close="onClose"
    >
      <a-form-model
        ref="editRuleRefForm"
        :model="editForm"
        :rules="editRules"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item label="网站域名" prop="key">
          <a-input v-model="editForm.key" placeholder="请输入网站域名" />
        </a-form-model-item>
        <a-form-model-item label="网站标题" prop="webTitle">
          <a-input v-model="editForm.webTitle" placeholder="请输入网站标题" />
        </a-form-model-item>
        <a-form-model-item label="菜单标题" prop="menuTitle">
          <a-input v-model="editForm.menuTitle" placeholder="请输入菜单标题" />
        </a-form-model-item>
        <a-form-model-item label="版权声明" prop="webCopyright">
          <a-input
            v-model="editForm.webCopyright"
            placeholder="请输入版权声明"
          />
        </a-form-model-item>
        <!--上传图片-->
        <a-form-model-item label="登录logo" prop="loginLogoAddress">
          <a-upload-dragger
            name="file"
            :multiple="false"
            :action="httpUploadSrc"
            :showUploadList="false"
            :beforeUpload="handleBeforeUpload"
            @change="handleChange"
          >
            <div v-if="!isShowLogo">
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">点击上传图片</p>
            </div>
            <div v-else>
              <img
                v-if="editForm.loginLogoAddressAll"
                :src="editForm.loginLogoAddressAll"
                width="100%"
                alt=""
              />
            </div>
          </a-upload-dragger>
        </a-form-model-item>
        <a-form-model-item label="菜单logo" prop="menuLogoAddress">
          <a-upload-dragger
            name="file"
            :multiple="false"
            :action="httpUploadSrc"
            :showUploadList="false"
            :beforeUpload="handleBeforeUpload"
            @change="menuLogoHandleChange"
          >
            <div v-if="!isShowLogoMenuLogo">
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">点击上传图片</p>
            </div>
            <div v-else>
              <img
                v-if="editForm.menuLogoAddressAll"
                :src="editForm.menuLogoAddressAll"
                width="100%"
                alt=""
              />
            </div>
          </a-upload-dragger>
        </a-form-model-item>
      </a-form-model>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="onClose"
          >关闭</a-button
        >
        <a-button type="primary" @click="editSubmit">确定</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: "WebsiteInfoManagement",
  data() {
    return {
      screenWidth: document.documentElement.clientWidth,
      //屏幕高度
      screenHeight: document.documentElement.clientHeight - 120,
      //图片上传地址
      httpUploadSrc:
        this._axios_env.SERVER +
        `/file/img?access_token=${localStorage
          .getItem("access_token")
          .substring(6, localStorage.getItem("access_token").length)}`,
      //所有网站设置列表
      websiteList: [],
      //编辑抽屉
      editVisible: false,
      //编辑表单对象
      editForm: {
        id: null,
        organizationId: "",
        key: "",
        webTitle: "",
        menuTitle: "",
        webCopyright: "",
        loginLogoAddress: "",
        loginLogoAddressAll: "",
        menuLogoAddress: "",
        menuLogoAddressAll: "",
      },
      //表单验证规则
      editRules: {
        key: [{ required: true, message: "请填写网站域名", trigger: "blur" }],
        webTitle: [
          { required: true, message: "请填写网站标题", trigger: "blur" },
        ],
        menuTitle: [
          { required: true, message: "请填写菜单标题", trigger: "blur" },
        ],
        webCopyright: [
          { required: true, message: "请填写版权声明", trigger: "blur" },
        ],
        loginLogoAddress: [
          { required: true, message: "请上传logo", trigger: "blur" },
        ],
        menuLogoAddress: [
          { required: true, message: "请上传logo", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {
    let token = window.localStorage.getItem("access_token");
    token = token.substr(6, token.length);
    console.log(token);
    const _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.screenHeight = document.documentElement.clientHeight - 120; //窗口高度
    };
    this.getWebsitSetting();
  },

  computed: {
    //判断是否显示Logo
    isShowLogo() {
      if (
        this.editForm.loginLogoAddress === "" ||
        this.editForm.loginLogoAddress === null
      ) {
        return false;
      } else {
        var reg = /null/;
        return !reg.test(this.editForm.loginLogoAddress);
      }
    },
    isShowLogoMenuLogo() {
      if (
        this.editForm.menuLogoAddress === "" ||
        this.editForm.menuLogoAddress === null
      ) {
        return false;
      } else {
        var reg = /null/;
        return !reg.test(this.editForm.menuLogoAddress);
      }
    },
  },

  methods: {
    //获取所有网站设置列表
    async getWebsitSetting() {
      const { data: res } = await this.http.pageSetting.getAllWebSetting();
      if (res.status === 200) {
        this.websiteList = res.data;
      }
    },

    //显示当前网站设置信息
    showWebsiteEdit(item) {
      this.editVisible = true;
      this.cleanEditForm();
      this.editForm.id = item.id;
      this.editForm.organizationId = item.organizationId; //机构id
      this.editForm.key = item.key;
      this.editForm.webTitle = item.webTitle;
      this.editForm.menuTitle = item.menuTitle;
      this.editForm.webCopyright = item.webCopyright;
      this.editForm.loginLogoAddress = item.loginLogoAddress;
      this.editForm.loginLogoAddressAll = item.loginLogoAddressAll;
      this.editForm.menuLogoAddress = item.menuLogoAddress;
      this.editForm.menuLogoAddressAll = item.menuLogoAddressAll;
    },
    cleanEditForm() {
      this.editForm = {
        id: null,
        organizationId: "",
        key: "",
        webTitle: "",
        menuTitle: "",
        webCopyright: "",
        loginLogoAddress: "",
        loginLogoAddressAll: "",
        menuLogoAddress: "",
        menuLogoAddressAll: "",
      };
    },

    //显示删除提示
    showWebsiteDelete(id) {
      if (id === null) {
        this.$message.error("该网站暂未设置信息");
        return;
      }
      let self = this;
      this.$confirm({
        title: "确定删除该网站设置吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: async () => {
          const { data: res } = await this.http.pageSetting.deleteWebSetting(
            id
          );
          if (res.status === 200) {
            self.$message.success(res.msg);
            self.getWebsitSetting();
          } else {
            self.$message.warning(res.msg);
            return false;
          }
        },
        onCancel() {},
      });
    },

    //关闭抽屉
    onClose() {
      this.editVisible = false;
        this.$refs.editRuleRefForm.resetFields();
    },

    //上传文件之前的钩子
    handleBeforeUpload(file, fileList) {
      const reg = /\.(png|jpg|jpeg|gif)$/gi;
      const filename = file.name;
      if (reg.test(filename) === false) {
        this.$message.error("请上传正确的图片格式！");
        return false;
      }
    },

    //上传文件改变时的状态
    handleChange({ file, fileList, event }) {
      if (file["response"] !== undefined) {
        this.editForm.loginLogoAddressAll = file["response"].data.allPath;
        this.editForm.loginLogoAddress = file["response"].data.path;
      }
    },
    menuLogoHandleChange({ file, fileList, event }) {
      if (file["response"] !== undefined) {
        this.editForm.menuLogoAddressAll = file["response"].data.allPath;
        this.editForm.menuLogoAddress = file["response"].data.path;
      }
    },

    //提交信息
    editSubmit() {
      this.$refs.editRuleRefForm.validate(async (valid) => {
        if (valid) {
          const { data: res } =
            await this.http.pageSetting.createOrUpdateWebSetting(this.editForm);
          if (res.status === 200) {
            this.editVisible = false;
            this.$message.success(res.msg);
            this.getWebsitSetting();
          } else {
            this.editVisible = false;
            this.$message.warning(res.msg);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.websit-info-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
  border: 1px solid #ebeef5;
  color: #303133;
  background-color: #fff;
  box-shadow: 0 1px 1px rgb(0 0 0 / 15%);
  border-radius: 2px;
}

.websit-tag {
  position: absolute;
  left: -0.5%;
  top: -0.5%;
  width: 65px;
  text-align: center;
  background-color: #fb7d21;
  color: #fff;
  border-bottom-right-radius: 2px;
}

.websit-card-head {
}

.websit-card-body {
  margin: 10px 0 0 5px;
}

.websit-card-tile {
  color: rgba(0, 0, 0, 0.76);
}

.websit-card-body-body {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.websit-card-box {
  /*display: flex;*/
  line-height: 30px;
}

.websit-company-name {
  color: rgba(2, 7, 13, 0.76);
  font-size: 20px;
  font-weight: 600;
  margin-left: 20px;
}

.websit-card-img {
  width: 50px;
  height: 50px;
}

.websit-card-img .logo-img {
  width: 100%;
}

.drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
</style>
